import AdsConfig from '../constants/UGSAdsConfigData';
import SupportLevelConfig from '../constants/UGSSupportLevelRankData';

class UGSRemoteConfigModel{
    constructor(ratioConfig, gameversion, configMinPlayerToShowTop3, multipleSessionConfig){
        this.type= 'settings';
        this.value = [
            {
                "key": "adminAccessToken",
                "type": "string",
                "value": '',
            },
            {
                "key": "Balance Config",
                "type": "json",
                "schemaId": null,
                "value": ratioConfig,
            },
            {
                "key": "Balance_Dev Config",
                "type": "json",
                "schemaId": null,
                "value": ratioConfig,
            },
            {
                "key": "adsConfig",
                "type": "json",
                "schemaId": null,
                "value": AdsConfig,
            },
            {
                "key": "supportLevelRank",
                "type": "json",
                "schemaId": null,
                "value": SupportLevelConfig,
            },
            {
                "key": "gameversion",
                "type": "json",
                "schemaId": null,
                "value": gameversion,
            },
            {
                "key": "configMinPlayerToShowTop3",
                "type": "int",
                "schemaId": null,
                "value": configMinPlayerToShowTop3,
            },
            {
                "key": "Multiple Session Config",
                "type": "json",
                "schemaId": null,
                "value": multipleSessionConfig,
            },
        ];
    }
}

export default UGSRemoteConfigModel