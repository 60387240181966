<template>
    <v-app>
        <v-app-bar app>
            Unity Service Dashboard
        </v-app-bar>
        <v-main>
            <UnityEnvironmentConfig/>
            <UnityEconomyConfig/>
            <UnityPlayerConfig/>
            <UnityOtherUGSConfig/>
        </v-main>
        <v-footer>
            <v-row>
                <v-col align="right">
                    <router-link  style="text-decoration: none; color: inherit;"
                                  to='/'>
                        <v-icon
                                color=red>
                            mdi-exit-to-app
                        </v-icon>
                    </router-link>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    import UnityPlayerConfig from '../modules/UnityPlayerConfig';
    import UnityEnvironmentConfig from '../modules/UnityEnvironmentConfig';
    import UnityEconomyConfig from '../modules/UnityEconomyConfig';
    import UnityOtherUGSConfig from "../modules/UnityOtherUGSConfig";

    export default {
        name: "UnityServiceDashboard",
        components: {
            UnityOtherUGSConfig,
            UnityPlayerConfig,
            UnityEnvironmentConfig,
            UnityEconomyConfig
        },
    }
</script>

<style scoped>

</style>