<template>
    <v-card elevation="0" class="pa-2 mx-10" min-height="600" max-height="600">
        <v-card-title>Game Version CONFIG</v-card-title>
        <v-row>
            <v-col cols="0" sm="0" md="0">
                <v-card
                        min-width="1500"
                        max-width="1500"
                        class="pa-2 my-0"
                        elevation="0"
                        outlined
                >
                    <div class="font-weight-black pl-2 pr-3 pt-2">Game Version</div>
                    <v-data-table
                            :headers="headers"
                            :items="gameversion"
                            class="elevation-1"
                            hide-default-footer
                            :options="{itemsPerPage:this.gameversion.length}"
                    >
                        <template v-slot:item.buildNumber="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.buildNumber"
                            >
                                {{ item.buildNumber }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.buildNumber"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                            @change="onChangeBuildNumber(item)"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.versionValue="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.versionValue"
                            >
                                {{ item.versionValue }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.versionValue"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                            @change="onChangeVersionValueNumber(item)"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.versionCode="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.versionCode"
                            >
                                {{ item.versionCode }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.versionCode"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
<!--
                        <template v-slot:item.descriptionEn="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.descriptionEn"
                            >
                                {{ item.descriptionEn }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.descriptionEn"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                            @change="onChangeDescription(item)"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.descriptionFr="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.descriptionFr"
                            >
                                {{ item.descriptionFr }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.descriptionFr"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.descriptionSp="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.descriptionSp"
                            >
                                {{ item.descriptionSp }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.descriptionSp"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.descriptionKh="{item}">
                            <v-edit-dialog
                                    :return-value.sync="item.descriptionKh"
                            >
                                {{ item.descriptionKh }}
                                <template v-slot:input>
                                    <v-text-field
                                            v-model="item.descriptionKh"
                                            label="Edit"
                                            single-line
                                            autofocus
                                            @focus="$event.target.select()"
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
-->
                    </v-data-table>

                    <v-text-field
                            label="Description English"
                            v-model="descriptionEn"
                    />
                    <v-text-field
                            label="Description French"
                            v-model="descriptionFr"
                    />
                    <v-text-field
                            label="Description Spanish"
                            v-model="descriptionSp"
                    />
                    <v-text-field
                            label="Description Khmer"
                            v-model="descriptionKh"
                    />

                    <v-card-actions>
                        <v-btn color="blue darken-1" text @click="didClose">Close</v-btn>
                        <v-btn color="green darken-1" text @click="didSave">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <PopupMessage v-if="messageAlert!==''"
                      pColor="green"
                      @onClose="messageAlert=''"
        >
            {{messageAlert}}
        </PopupMessage>
    </v-card>
</template>

<script>
    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";

    export default {
        name: "UGSGameVersionConfig",
        components: {
            PopupMessage,
        },
        data() {
            return {
                gameversion: [],
                descriptionEn: '',
                descriptionFr: '',
                descriptionSp: '',
                descriptionKh: '',
                messageAlert: '',
                headers: [
                    {text: 'Platform', value: 'platform'},
                    {
                        text: 'BuildNumber',
                        value: 'buildNumber',
                    },
                    {
                        text: 'VersionValue',
                        value: 'versionValue',
                    },
                    {
                        text: 'VersionCode',
                        value: 'versionCode',
                    },
                    /*
                    {
                        text: 'descriptionEn',
                        value: 'descriptionEn',
                    },
                    {
                        text: 'descriptionFr',
                        value: 'descriptionFr',
                    },
                    {
                        text: 'descriptionKh',
                        value: 'descriptionKh',
                    },
                    {
                        text: 'descriptionSp',
                        value: 'descriptionSp',
                    },*/
                ]
            }
        },
        created() {
            this.gameversion = this.$store.state.gameversion;
            this.descriptionEn = this.gameversion[0].descriptionEn;
            let build = this.gameversion[0].buildNumber;
            let code = this.gameversion[0].versionCode;
            if(this.descriptionEn === ""){
                this.descriptionEn = this.getSampleDescription('[VersionCode]','[BuildNumber]');
                this.descriptionFr = this.getSampleDescription('[VersionCode]','[BuildNumber]');
                this.descriptionSp = this.getSampleDescription('[VersionCode]','[BuildNumber]');
                this.descriptionKh = this.getSampleDescription('[VersionCode]','[BuildNumber]');
            } else {
                this.descriptionEn = this.prepareReplaceVersionAndBuild(this.descriptionEn, code, build);
                this.descriptionFr = this.prepareReplaceVersionAndBuild(this.gameversion[0].descriptionFr, code, build);
                this.descriptionSp = this.prepareReplaceVersionAndBuild(this.gameversion[0].descriptionSp, code, build);
                this.descriptionKh = this.prepareReplaceVersionAndBuild(this.gameversion[0].descriptionKh, code, build);
            }
        },
        methods:{
            onChangeBuildNumber(item){
                item.buildNumber = parseInt(item.buildNumber);
            },
            onChangeVersionValueNumber(item){
                item.versionValue = parseInt(item.versionValue);
            },
            onChangeVersionCodeNumber(item){
                item.versionCode = parseInt(item.versionCode);
            },
            // onChangeDescription(item){
            //     item.description = item.descriptionEn;
            // },
            didClose(){
                this.$emit('close');
            },
            didSave(){
                this.gameversion.forEach(version =>{
                    version.descriptionEn = this.replaceVersionAndBuild(this.descriptionEn, version.versionCode, version.buildNumber);
                    version.description = version.descriptionEn;

                    version.descriptionFr = this.replaceVersionAndBuild(this.descriptionFr, version.versionCode, version.buildNumber);
                    version.descriptionSp = this.replaceVersionAndBuild(this.descriptionSp, version.versionCode, version.buildNumber);
                    version.descriptionKh = this.replaceVersionAndBuild(this.descriptionKh, version.versionCode, version.buildNumber);
                });

                this.$store.commit(this.$store.state.commitActions.PUSH_GAMEVERSION, this.gameversion);
                this.messageAlert = 'Saved';
            },
            replaceVersionAndBuild(desc, code, build){
                desc = desc.replaceAll('[VersionCode]', code);
                desc = desc.replaceAll('[BuildNumber]', build);
                return desc;
            },
            prepareReplaceVersionAndBuild(desc, code, build){
                desc = desc.replaceAll(code, '[VersionCode]');
                desc = desc.replaceAll(build, '[BuildNumber]');
                return desc;
            },

            updateDescription(version){
                version.description = this.getSampleDescription(version.versionCode, version.buildNumber);
                version.descriptionEn = this.getSampleDescription(version.versionCode, version.buildNumber);
                version.descriptionFr = this.getSampleDescription(version.versionCode, version.buildNumber);
                version.descriptionKh = this.getSampleDescription(version.versionCode, version.buildNumber);
                version.descriptionSp = this.getSampleDescription(version.versionCode, version.buildNumber);
            },
            getSampleDescription(versionCode, buildNumber){
                return "Version <color=#ffa500ff>"+versionCode+"</color> build "+buildNumber+" of Hok Mok is now available! Please note that it may take time for the new update to go live. Download the new version to continue your adventure.";
            },
        },
    }
</script>

<style scoped>

</style>