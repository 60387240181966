<template>
<v-card elevation="0" class="pa-2 mx-10" min-height="270" max-height="270">
    <v-card-title>OTHER UGS CONFIG</v-card-title>
    <v-row>
        <v-col cols="0" sm="0" md="0">
            <v-card
                    min-width="430"
                    max-width="430"
                    class="pa-2 my-0"
                    elevation="0"
                    outlined
            >
                <v-row>
                    <v-col cols="8">
                        <div class="font-weight-black pl-2 pr-3 pt-2">Add remote config data</div>
                    </v-col>
                    <v-col cols="5" class="pb-4">
                        <v-btn
                            @click="showRemoteEditScreen=true"
                        >
                            Edit GameVersion
                        </v-btn>
                    </v-col>
                    <v-col class="pl-6">
                        <v-btn
                                @click="showMultipleSessionEditScreen=true"
                        >
                            Edit Multiple Session
                        </v-btn>
                    </v-col>
                    <v-col class="pb-5">
                        <v-btn
                                @click="doAddRemoteConfig"
                        >
                            Sync with {{this.environmentName()}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="0" sm="0" md="0" class="pl-3">
            <v-card
                    min-width="330"
                    max-width="330"
                    class="pa-2 my-0"
                    elevation="0"
                    outlined
            >
                <v-row>
                    <v-col cols="8">
                        <div class="font-weight-black pl-2 pr-3 pt-2">Add leaderboard config</div>
                    </v-col>
                    <v-col class="pb-5">
                        <v-btn
                                @click="doAddLeaderboardConfig"
                        >
                            Add to {{this.environmentName()}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <SmpDialog
            :should-show="this.showRemoteEditScreen"
            @close="showRemoteEditScreen=false"
    >
        <UGSGameVersionConfig
            @close="showRemoteEditScreen=false"
        />
    </SmpDialog>

    <SmpDialog
            v-if="showMultipleSessionEditScreen"
            :should-show="this.showMultipleSessionEditScreen"
            @close="showMultipleSessionEditScreen=false"
    >
        <UGSMultipleSessionConfig
                :pMultipleSessionConfig="this.$store.state.multipleSessionConfig"
                @close="closeMultipleSession"
                @save="saveMultipleSession"
        />
    </SmpDialog>

    <v-overlay :value="isLoading">
        <v-progress-circular
                indeterminate
                size="64"
        ></v-progress-circular>
    </v-overlay>

    <PopupMessage v-if="messageAlert!==''"
                  :pColor="messageColor"
                  @onClose="messageAlert=''"
    >
        {{messageAlert}}
    </PopupMessage>
</v-card>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import UnityServiceApi from '../api/UnityServiceApi';
    import UGSRemoteConfigModel from '../class/UGSRemoteConfigModel';
    import UGSLeaderboardConfig from '../constants/UGSLeaderboardConfig';
    import GamePlayRatioApdater from "@/kpi-editor/classes/GamePlayRatioApdater";
    import SmpDialog from "@/item-editor/modules/SmpDialog.vue";
    import UGSGameVersionConfig from "./UGSGameVersionConfig.vue";
    import UGSMultipleSessionConfig from "./UGSMultipleSessionConfig";

    export default {
        name: "UnityOtherUGSConfig",
        props:[],
        components: {
            PopupMessage,
            SmpDialog,
            UGSGameVersionConfig,
            UGSMultipleSessionConfig
        },
        data() {
            return {
                isLoading: false,
                messageAlert: '',
                messageColor: 'orange',
                playerIdDelete: '',
                playerDeleteCounter: 100,
                cloneEnvironmentSourceName: '',
                environmentRef: [],
                showRemoteEditScreen: false,
                showMultipleSessionEditScreen: false,
            }
        },
        methods: {
            environmentId(){
                return this.$store.state.ugsSetting.environmentId;
            },
            environmentName(){
                return this.$store.state.ugsSetting.environmentName;
            },
            doAddRemoteConfig(){
                this.isLoading = true;
                let ratioConfig = this.getGamePlayRatioWithAllConfig();
                let gameversion = this.$store.state.gameversion;
                let configMinPlayerToShowTop3 = this.$store.state.configMinPlayerToShowTop3;
                let multipleSessionConfig = this.$store.state.multipleSessionConfig;
                let config = new UGSRemoteConfigModel(ratioConfig, gameversion, configMinPlayerToShowTop3, multipleSessionConfig);
                UnityServiceApi.addRemoteConfig(config, this.environmentId())
                    .then(result => {
                        this.setAlertMessage(result.data.message, result.data.status !== 200);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to add remote config: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            doAddLeaderboardConfig(){
                this.isLoading = true;
                UnityServiceApi.addLeaderboardConfig(this.getLeaderboardConfig(), this.environmentId())
                    .then(result => {
                        this.setAlertMessage(result.data.message, result.data.status !== 200 && result.data.status !== 409);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to add leaderboard config: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            getLeaderboardConfig(){
                let configs = UGSLeaderboardConfig;
                const dateCopy = new Date();
                dateCopy.setSeconds(dateCopy.getSeconds() + 200);
                configs.forEach(config =>{
                    if(config.resetConfig != null){
                        config.resetConfig.start = dateCopy;
                    }
                });
                return configs;
            },

            getGamePlayRatioWithAllConfig(){
                let ratioConfig = this.$store.state.ratios;
                let supportSkill = this.$store.state.skillList;
                let supportUnlock = this.$store.state.unlockSupportSetting;
                //convert from ratio editor to ratio like game play
                return GamePlayRatioApdater.getGamePlayRatioWithAllConfig(ratioConfig, supportUnlock, supportSkill);
            },
            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            },

            closeMultipleSession(){
                this.showMultipleSessionEditScreen = false
            },

            saveMultipleSession(){
                this.closeMultipleSession();
                this.doAddRemoteConfig();
            },
        }
    }
</script>

<style scoped>

</style>