import Vue from 'vue';
export const GlobalEvents = new Vue();

export const EVENT_ON_ITEM_SAVED = 'onItemSaved';
export const EVENT_TO_CHANGE_ICON_ITEM = 'toChangeItemIconForm';
export const EVENT_TO_UPDATE_ITEM = 'toUpdateItemForm';
export const EVENT_TO_OPEN_ITEM_FORM = 'toOpenItemForm';

export const EVENT_TO_CHANGE_ICON_SIBLING_ITEM = 'toChangeSiblingItemIconForm';
export const EVENT_TO_UPDATE_SIBLING_ITEM = 'toUpdateSiblingItemForm';
export const EVENT_TO_OPEN_SIBLING_ITEM_FORM = 'toOpenSiblingItemForm';

export const EVENT_TO_UPDATE_AUTOMATE_ITEM_FORM = 'toUpdateAutomateItemForm';
export const EVENT_UPDATED_AUTOMATE_ITEM_FORM = 'updatedAutomateItemForm';

export const EVENT_UPDATED_EVIRONMENT_CONFIG = 'updatedEnvironmentConfig';

export const EVENT_REFRESH_MARKET = 'toRefreshMarket';

export const EVENT_TO_UPDATE_BEST_HERO = 'toUpdateBestHero';
export const EVENT_TO_SELECT_HERO = 'toSelectHero';
export const EVENT_TO_SELECT_PET = 'toSelectPet';