<template>
<v-card elevation="0" class="pa-2 mx-10" min-height="270" max-height="270">
    <v-card-title>ECONOMY CONFIGURATION</v-card-title>
    <v-row>
        <v-col cols="0" sm="0" md="0">
            <v-card
                    min-width="450"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <v-row>
                    <v-col cols="8">
                        <div class="font-weight-black pl-2 pr-3 pt-2">Add SMPGem resource</div>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="doPushSmpGem"
                        >
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <div class="font-weight-black pl-2 pr-3 pt-2">Publish economy resource config</div>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="doPublish"
                        >
                            Publish
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <div class="font-weight-black pl-2 pr-3 pt-2">Auto Publish on pushing Item</div>
                    </v-col>
                    <v-col>
                        <v-checkbox
                                v-model="$store.state.ugsSetting.isAutoPublish"
                                class="pt-0 mt-2"
                                @change="onAutoPushChange"
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="0" sm="0" md="0" class="pl-3">
            <v-card
                    min-height="130"
                    max-height="130"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-2">Clone Economy Item and Shop Resource</div>
                <v-row>
                    <v-col>
                        <v-card min-width="220"
                                max-width="220"
                                elevation="0">
                            <v-combobox
                                    v-model="cloneEnvironmentSourceName"
                                    :items="environmentRef"
                                    label="Environment Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                            >
                            </v-combobox>
                        </v-card>
                    </v-col>
                    <v-col class="pt-5">
                        <v-btn
                                @click="doClone"
                        >
                            Clone to {{environmentName()}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <v-overlay :value="isLoading">
        <v-progress-circular
                indeterminate
                size="64"
        ></v-progress-circular>
    </v-overlay>

    <PopupMessage v-if="messageAlert!==''"
                  :pColor="messageColor"
                  @onClose="messageAlert=''"
    >
        {{messageAlert}}
    </PopupMessage>
</v-card>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import UnityServiceApi from '../api/UnityServiceApi';
    import {GlobalEvents, EVENT_UPDATED_EVIRONMENT_CONFIG} from "@/item-editor/events/GlobalEvents";

    export default {
        name: "UnityEconomyConfig",
        props:[],
        components: {
            PopupMessage,
        },
        created() {
            this.initEnvironmentRef();
            GlobalEvents.$on(EVENT_UPDATED_EVIRONMENT_CONFIG, () => this.initEnvironmentRef());
        },
        data() {
            return {
                isLoading: false,
                messageAlert: '',
                messageColor: 'orange',
                playerIdDelete: '',
                playerDeleteCounter: 100,
                cloneEnvironmentSourceName: '',
                environmentRef: [],
            }
        },
        methods: {
            initEnvironmentRef(){
                let envList = this.$store.state.ugsSetting.environmentList;
                this.environmentRef = [];
                envList.forEach(e => {
                    if(this.environmentId() !== e.id){
                        this.environmentRef.push(e.name);
                    }
                });
            },
            doPushSmpGem(){
                this.isLoading = true;
                UnityServiceApi.addSmpGem(this.environmentId())
                    .then(result => {
                        this.setAlertMessage(result.message);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to add smpGem: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            doPublish(){
                this.isLoading = true;
                UnityServiceApi.publishEconomy(this.environmentId())
                    .then(result => {
                        this.setAlertMessage(result.message);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1500);

                    })
                    .catch(error => {
                        this.setAlertMessage('Failed to publish: '+error, true);
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 200);
                    });
            },
            async doClone(){
                let envList = this.$store.state.ugsSetting.environmentList;
                let ref = envList.find(r => r.name === this.cloneEnvironmentSourceName);
                if(ref){
                    if(ref.id === this.environmentId()){
                        this.setAlertMessage('Environment id is the same', true);
                    } else {
                        this.isLoading = true;
                        let result = await UnityServiceApi.cloneResource(ref.id, this.environmentId());
                        if(result.status === 200){
                            this.setAlertMessage('Clone completed!!!', false);
                        } else {
                            console.log(result);
                            this.setAlertMessage('Can not Clone!!! '+result.message+" | "+result.error, true);
                        }
                        this.isLoading = false;
                    }
                } else {
                    this.setAlertMessage('Invalid environment id', true);
                }
            },
            environmentId(){
                return this.$store.state.ugsSetting.environmentId;
            },
            environmentName(){
               return this.$store.state.ugsSetting.environmentName;
            },
            onAutoPushChange(){
                this.$store.commit(this.$store.state.commitActions.PUSH_UGSSETTING);
            },

            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            }
        }
    }
</script>

<style scoped>

</style>