module.exports = [
    {
        "startLevel" : 1,
        "endLevel" : 1110,
        "rankType" : 0
    },
    {
        "startLevel" : 1111,
        "endLevel" : 2221,
        "rankType" : 1
    },
    {
        "startLevel" : 2222,
        "endLevel" : 5554,
        "rankType" : 2
    },
    {
        "startLevel" : 5555,
        "endLevel" : 7776,
        "rankType" : 3
    },
    {
        "startLevel" : 7777,
        "endLevel" : -1,
        "rankType" : 4
    }
];