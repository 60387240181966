<template>
<v-card elevation="0" class="pa-2 mx-10" min-height="470" max-height="470">
    <v-row>
        <v-col >
            <v-card-title>ENVIRONMENT SETTINGS</v-card-title>
        </v-col>
        <v-col class="pt-3 mt-3"
        >
            <DownloadUserGuid
                    :pdfUrl=pdfUrl
                    :pdfFileName=pdfFileName
            />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="0" sm="0" md="0">
            <v-card
                    min-width="550"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-2">Local Environment Id Selected</div>
                <v-row>
                    <v-col>
                        <v-card max-width="450"
                                elevation="0">
                            <v-combobox
                                    v-model="environmentSelected"
                                    :items="environmentRef"
                                    label="Environment Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                                    @change="onEnvironmentChange"
                            >
                            </v-combobox>
                            <div class="font-weight-black">Environment Id:</div>
                            <v-label>{{environmentId}}</v-label>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="doApply"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="0" sm="0" md="0" class="pl-3" v-if="!pIsEnvSelectOnly">
            <v-card
                    min-width="450"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-0">Add Local Environment</div>
                <v-row>
                    <v-col class="pt-1">
                        <v-card max-width="450"
                                elevation="0">
                            <v-text-field
                                    label="Environment Name"
                                    v-model="addEnvironmentName"
                            />
                            <v-text-field
                                    label="Environment Id"
                                    v-model="addEnvironmentId"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="doAddEnvironment"
                        >
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="0" sm="0" md="0" class="pl-0 pt-3" v-if="!pIsEnvSelectOnly">
            <v-card
                    min-height="130"
                    max-height="130"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-2">Delete Environment</div>
                <v-row>
                    <v-col>
                        <v-card min-width="260"
                                max-width="260"
                                elevation="0">
                            <v-combobox
                                    v-model="environmentDelete"
                                    :items="environmentRef"
                                    label="Environment Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                            >
                            </v-combobox>
                        </v-card>
                    </v-col>
                    <v-col class="pt-5">
                        <v-btn
                                @click="doDelete"
                        >
                            Delete to {{environmentDelete}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <PopupMessage v-if="messageAlert!==''"
                  :pColor="messageColor"
                  @onClose="messageAlert=''"
    >
        {{messageAlert}}
    </PopupMessage>
</v-card>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import {GlobalEvents, EVENT_UPDATED_EVIRONMENT_CONFIG} from "@/item-editor/events/GlobalEvents";
    import DownloadUserGuid from "./DownloadUserGuid";

    export default {
        name: "UnityEnvironmentConfig",
        props: {
            'pIsEnvSelectOnly': {
                type: Boolean,
                required: false
            },
        },
        components: {
            PopupMessage,
            DownloadUserGuid
        },
        data() {
            return {
                environmentId: '',
                addEnvironmentName: '',
                addEnvironmentId: '',
                environmentSelected: '',
                environmentRef: [],
                messageAlert: '',
                messageColor: 'orange',
                environmentDelete: '',

                pdfUrl: 'https://prod.hokmok.com/uploads/unity_service_dashboard_guid_37e2f017e5.pdf',//'/path/to/my/file.pdf',
                pdfFileName: 'unity-service-dashboard-guid.pdf'
            }
        },
        created() {
            this.initEnvironmentRef();
        },
        methods: {
            initEnvironmentRef(){
                this.environmentId = this.$store.state.ugsSetting.environmentId;
                this.environmentSelected = this.$store.state.ugsSetting.environmentName;
                let envList = this.$store.state.ugsSetting.environmentList;
                this.environmentRef = [];
                envList.forEach(e => {
                    this.environmentRef.push(e.name);
                });
                this.environmentDelete = envList[0].name;
            },
            applyIdToSave(){
                this.$store.state.ugsSetting.environmentId = this.environmentId;
                this.$store.state.ugsSetting.environmentName = this.environmentSelected;
            },
            doApply(){
                if(this.environmentId === ''){
                    this.setAlertMessage('environmentId invalid!', true);
                } else {
                    this.applyIdToSave();
                    this.pushConfig();
                    this.setAlertMessage('Saving is success!', false);
                }
            },
            doAddEnvironment(){
                if(this.addEnvironmentName === ''){
                    this.setAlertMessage('Environment name is require!', true);
                } else if(this.addEnvironmentId === ''){
                    this.setAlertMessage('Environment id is require!', true);
                } else {
                    let envList = this.$store.state.ugsSetting.environmentList;
                    let ref = envList.find(r => r.name === this.addEnvironmentName || r.id === this.addEnvironmentId);
                    if(ref){
                        this.setAlertMessage('this environment adding is exiting!', true);
                    } else {
                        envList.push({
                           name: this.addEnvironmentName,
                           id: this.addEnvironmentId,
                        });
                        this.environmentRef.push(this.addEnvironmentName);
                        this.addEnvironmentName = '';
                        this.addEnvironmentId = '';
                        this.pushConfig();
                        this.setAlertMessage('add environment to local is success!', false);
                    }
                }
            },
            pushConfig(){
                this.$store.commit(this.$store.state.commitActions.PUSH_UGSSETTING);
                GlobalEvents.$emit(EVENT_UPDATED_EVIRONMENT_CONFIG);
            },
            onEnvironmentChange(){
                let envList = this.$store.state.ugsSetting.environmentList;
                let ref = envList.find(r => r.name === this.environmentSelected);
                if(ref){
                    this.environmentId = ref.id;
                } else {
                    this.environmentId = '';
                }
            },
            doDelete(){
                if(this.environmentDelete === ''){
                    this.setAlertMessage('environmentId invalid!', true);
                } else if(this.environmentDelete === this.environmentSelected){
                    this.setAlertMessage('environmentId is was selected!', true);
                } else {
                    let envList = this.$store.state.ugsSetting.environmentList;
                    let ref = envList.find(r => r.name === this.environmentDelete);
                    if(ref){
                        envList.splice(envList.indexOf(ref), 1);
                        this.setAlertMessage('delete success '+this.environmentDelete, false);
                        this.environmentDelete = envList[0].name;
                        this.pushConfig();
                        this.initEnvironmentRef();
                    } else {
                        this.setAlertMessage('environmentId invalid!', true);
                    }
                }
            },
            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            },
        }
    }
</script>

<style scoped>

</style>