<template>
    <v-card
            min-width="550"
            class="mt-2"
            elevation="0"
    >
        <div class="font-weight-black">MULTIPLE SESSION CONFIG</div>
        <v-card
                class="pt-4 pb-0"
                elevation="0">
            <v-subheader>Hero base dmg percentage from his element vs monster</v-subheader>
            <v-slider
                    v-model="multipleSessionConfig.heroBaseDmgPercent"
                    track-color="grey"
                    always-dirty
                    thumb-label
                    min="0"
                    max="100"
            >
                <template v-slot:append>
                    <v-text-field
                            v-model="multipleSessionConfig.heroBaseDmgPercent"
                            class="mt-0 pt-0"
                            type="number"
                            min="0"
                            max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
        <v-card
                class="pt-4 pb-0"
                elevation="0">
            <v-subheader>Hero hp percentage per support element in team join</v-subheader>
            <v-slider
                    v-model="multipleSessionConfig.hpPercentPerElement"
                    track-color="grey"
                    always-dirty
                    thumb-label
                    min="0"
                    max="100"
            >
                <template v-slot:append>
                    <v-text-field
                            v-model="multipleSessionConfig.hpPercentPerElement"
                            class="mt-0 pt-0"
                            type="number"
                            min="0"
                            max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
        <v-card
                class="pt-4 pb-0"
                elevation="0">
            <v-subheader>Hero dmg percentage per pet element in team join</v-subheader>
            <v-slider
                    v-model="multipleSessionConfig.dmgPercentPetElement"
                    track-color="grey"
                    always-dirty
                    thumb-label
                    min="0"
                    max="100"
            >
                <template v-slot:append>
                    <v-text-field
                            v-model="multipleSessionConfig.dmgPercentPetElement"
                            class="mt-0 pt-0"
                            type="number"
                            min="0"
                            max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="didClose">Close</v-btn>
            <v-btn color="green darken-1" text @click="didSave">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import lodash from "lodash";

    export default {
        name: "UGSMultipleSessionConfig",
        props: ["pMultipleSessionConfig"],
        components: {
        },
        data() {
            return {
                messageAlert: '',
                multipleSessionConfig: {},
            }
        },
        created() {
            this.multipleSessionConfig = lodash.clone(this.pMultipleSessionConfig);
        },
        methods:{
            didClose(){
                this.$emit('close');
            },
            didSave(){
                this.$store.commit(this.$store.state.commitActions.PUSH_MULTIPLE_SESSION, this.multipleSessionConfig);
                this.$emit('save');
            },
        },
    }
</script>

<style scoped>

</style>