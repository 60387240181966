module.exports = [
    {
        "id": "allTime",
        "name": "allTime",
        "updateType": "keepBest",
        "sortOrder": "desc",
        "bucketSize": 0,
        "resetConfig": null,
        "partitionKeys": null,
        "tieringConfig": null
    },
    {
        "id": "daily",
        "name": "daily",
        "updateType": "keepBest",
        "sortOrder": "desc",
        "bucketSize": 0,
        "resetConfig": {
            "start": "",//"2023-08-11T05:00:00Z",
            "schedule": "@every 24h",
            "archive": false
        },
        "partitionKeys": null,
        "tieringConfig": null
    },
    {
        "id": "perHours",
        "name": "perHours",
        "updateType": "keepBest",
        "sortOrder": "desc",
        "bucketSize": 0,
        "resetConfig": {
            "start": "",
            "schedule": "@every 1h",
            "archive": false
        },
        "partitionKeys": null,
        "tieringConfig": null
    },
    {
        "id": "weekly",
        "name": "weekly",
        "updateType": "keepBest",
        "sortOrder": "desc",
        "bucketSize": 0,
        "resetConfig": {
            "start": "",
            "schedule": "@every 168h",
            "archive": false
        },
        "partitionKeys": null,
        "tieringConfig": null
    }
];